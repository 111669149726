@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Crimson+Pro:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: 'Averta';
  src: url('../src/assets/font/AvertaDemo-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

body{
  font-family: 'Averta',sans-serif;
}

.crimson-font{
  font-family: 'Crimson Pro', serif;
}

.bg-heroImage{
  background-image: url(../src/assets/images/heroImg.jpg);
}

/* 
.bg-aboutUs-Header{
  background-image: url(../src/assets/images/about-header.jpg);
  background-position: center;
} */

.bg-footer{
  background-image: url(../src/assets/images/footer-img.jpg);
  height: 300px;
  background-position: center;
  background-size: cover;
  border-radius: 5px;
}

.toastBody{
  font-family: 'Crimson Pro', serif;
}

/* CUSTOM SCROLLBAR */

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0f2fe;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1DA1F280;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1DA1F2;
}

